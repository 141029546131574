import dynamic from "next/dynamic";

const InfoIcon = dynamic(() => import("./InfoIcon"));
const InstagramIcon = dynamic(() => import("./InstagramIcon"));
const LogoIcon = dynamic(() => import("./LogoIcon"));
const FacebookIcon = dynamic(() => import("./FacebookIcon"));
const YoutubeIcon = dynamic(() => import("./YoutubeIcon"));
const DatepickerArrowsIcon = dynamic(() => import("./DatepickerArrowsIcon"));
const LocationIcon = dynamic(() => import("./LocationIcon"));
const SearchIcon = dynamic(() => import("./SearchIcon"));
const ArrowDownIcon = dynamic(() => import("./ArrowDownIcon"));
const TimesIcon = dynamic(() => import("./TimesIcon"));
const PaymentOptionIcon1 = dynamic(() => import("./PaymentOptionIcon1"));
const PaymentOptionIcon2 = dynamic(() => import("./PaymentOptionIcon2"));
const HeartIcon = dynamic(() => import("./HeartIcon"));
const AttentionIcon = dynamic(() => import("./AttentionIcon"));
const FaturaIcon = dynamic(() => import("./FaturaIcon"));
const CallIcon = dynamic(() => import("./CallIcon"));
const WhatsappnewIcon = dynamic(() => import("./WhatsappnewIcon"));
const ReverseIcon = dynamic(() => import("./ReverseIcon"));
const MapIcon = dynamic(() => import("./MapIcon"));
const Icons_new = dynamic(() => import("./Icons_new"));
const Icons_new2 = dynamic(() => import("./Icons_new2"));
const Icons_new3 = dynamic(() => import("./Icons_new3"));
const Icons_new4 = dynamic(() => import("./Icons_new4"));
const WorkingIcon = dynamic(() => import("./WorkingIcon"));
const ProblemreportIcon = dynamic(() => import("./ProblemreportIcon"));
const WheltimeIcon = dynamic(() => import("./WheltimeIcon"));
const GancelIcon = dynamic(() => import("./GancelIcon"));
const GuestconfirmationIcon = dynamic(() => import("./GuestconfirmationIcon"));
const CloseIcon = dynamic(() => import("./CloseIcon"));
const VillaLocationIcon = dynamic(() => import("./VillaLocationIcon"));
const PlayButtonIcon = dynamic(() => import("./PlayButtonIcon"));
const DetailIncludedPriceIcon = dynamic(() =>
  import("./DetailIncludedPriceIcon")
);
const BarIcon = dynamic(() => import("./BarIcon"));
const CallCenterIcon = dynamic(() => import("./CallCenterIcon"));
const UserIcon = dynamic(() => import("./UserIcon"));
const ClockIcon = dynamic(() => import("./ClockIcon"));
const PaginationPrevIcon = dynamic(() => import("./PaginationPrevIcon"));
const PaginationNextIcon = dynamic(() => import("./PaginationNextIcon"));
const ShareIcon = dynamic(() => import("./ShareIcon"));
const HousekeepingIcon = dynamic(() => import("./HousekeepingIcon"));
const CameraIcon = dynamic(() => import("./CameraIcon"));
const TeamIcon = dynamic(() => import("./TeamIcon"));
const CommentIcon = dynamic(() => import("./CommentIcon"));
const WalletIcon = dynamic(() => import("./WalletIcon"));
const UserodemeIcon = dynamic(() => import("./UserodemeIcon"));
const MailodemeIcon = dynamic(() => import("./MailodemeIcon"));
const PhoneodemeIcon = dynamic(() => import("./PhoneodemeIcon"));
const EditodemeIcon = dynamic(() => import("./EditodemeIcon"));
const LockodemeIcon = dynamic(() => import("./LockodemeIcon"));
const LocationPinIcon = dynamic(() => import("./LocationPin"));
const StarIcon = dynamic(() => import("./StarIcon"));
const KtbIcon = dynamic(() => import("./Ktb"));

const Icon = ({ name, size = 24, className }) => {
  const icons = {
    info: InfoIcon,
    logo: LogoIcon,
    instagram: InstagramIcon,
    facebook: FacebookIcon,
    youtube: YoutubeIcon,
    datepickerArrows: DatepickerArrowsIcon,
    location: LocationIcon,
    search: SearchIcon,
    ArrowDown: ArrowDownIcon,
    Times: TimesIcon,
    PaymentOption1: PaymentOptionIcon1,
    PaymentOption2: PaymentOptionIcon2,
    Heart: HeartIcon,
    attention: AttentionIcon,
    fatura: FaturaIcon,
    call: CallIcon,
    whatsappnew: WhatsappnewIcon,
    reverse: ReverseIcon,
    map: MapIcon,
    newIcon1: Icons_new,
    newIcon2: Icons_new2,
    newIcon3: Icons_new3,
    newIcon4: Icons_new4,
    working: WorkingIcon,
    villaLocation: VillaLocationIcon,
    PlayButton: PlayButtonIcon,
    DetailIncludedPrice: DetailIncludedPriceIcon,
    Bar: BarIcon,
    CallCenter: CallCenterIcon,
    problemreport: ProblemreportIcon,
    wheltime: WheltimeIcon,
    gancel: GancelIcon,
    guestconfirmation: GuestconfirmationIcon,
    close: CloseIcon,
    user: UserIcon,
    clock: ClockIcon,
    PaginationPrev: PaginationPrevIcon,
    PaginationNext: PaginationNextIcon,
    Share: ShareIcon,
    Wallet: WalletIcon,
    Comment: CommentIcon,
    Camera: CameraIcon,
    Housekeeping: HousekeepingIcon,
    Team: TeamIcon,
    userodeme: UserodemeIcon,
    mailodeme: MailodemeIcon,
    phoneodeme: PhoneodemeIcon,
    editodeme: EditodemeIcon,
    lockodeme: LockodemeIcon,
    LocationPin: LocationPinIcon,
    Star: StarIcon,
    ktb: KtbIcon,
  };

  const Component = icons[name];
  return <Component size={size} className={className} />;
};

export { Icon };
