import React, { useState, useContext } from "react";
import dynamic from "next/dynamic";

import { BiUser } from "@react-icons/all-files/bi/BiUser";

import Link from "next/link";
import { Icon } from "../../icons/icons";
import { StoreContext } from "../../context/context";
import { fetcher } from "../../helper/fetcher";
import { useTranslation } from "../../context/TranslationContext";

const MobileMenuContent = dynamic(() =>
  import("../../static/Header/MobileMenuContent")
);
const MobileTopBar = () => {
  const { reservations } = useTranslation();
  const { favouritesIsOpen, setFavouritesIsOpen } = useContext(StoreContext);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(false);
  const [MobileMenuIsOpen, setMobileMenuIsOpen] = useState(0);

  const isOpen = favouritesIsOpen;

  function OpenMobileMenu() {
    setMobileMenuIsOpen(true);
    LoadData();
  }
  function CloseMobileMenu() {
    if (MobileMenuIsOpen === true) setMobileMenuIsOpen(false);
  }

  async function LoadData() {
    if (!data)
      await fetcher(process.env.NEXT_PUBLIC_API + "/MobileMenu").then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  return (
    <div className="flex gap-x-4 ml-auto lg:hidden items-center">
      <button
        aria-label="Favoriler"
        onClick={() => {
          setFavouritesIsOpen(!isOpen);
        }}
        className={"border rounded-full p-1 text-red-500"}
      >
        <Icon name={"Heart"} />
      </button>

      <Link href={reservations.url}>
        <a
          aria-label={reservations.ariaLabel}
          className={"mm:inline-block ms:hidden "}
        >
          <BiUser size={32} fontWeight={200}></BiUser>
        </a>
      </Link>

      <button aria-label="Mobil Menü" onClick={OpenMobileMenu}>
        <Icon name={"Bar"} />
      </button>

      {/*Mobile Menü*/}
      {MobileMenuIsOpen ? (
        <MobileMenuContent
          closeMobileMenu={CloseMobileMenu}
          data={data}
          isLoaded={isLoaded}
          error={error}
        />
      ) : (
        ""
      )}
      {/*Mobile Menü*/}
    </div>
  );
};

export default MobileTopBar;
